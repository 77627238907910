.tableContainer {
    a {
        color: unset !important;
        text-decoration: none !important;
    }

    .tableCol {
        display: block;
        max-width: 240px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
