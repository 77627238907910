.flexCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 100%;
    max-width: 500px;
}

.buttonPrimary {
    background-color: #92979a;
    color: #ffffff;
    padding: 4px 4px;
    border-radius: 4px;
    border: solid 1px #82878a;
    min-width: 80px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.2s;
    font-size: 15px;

    &:hover {
        background-color: #82878a;
    }
}
