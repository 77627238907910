$navy: #00022b;
$steel: #7c7e95;
$cadet-blue: #b3b3bf;
$ghost: #ccccd5;
$blue: #3a41df;
$dark-blue: #1e25bd;
$intense-blue: #2028e6;
$red: #d70f27;
$green: #00b267;
$yellow: #fcc127;
$white: #ffffff;
$zircon: #f6f9ff;
$tab-blue: #3138d6;
$tab-green: #00874e;
$gold: #ffcc45;
