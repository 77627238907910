@import "../../../styles/variables";

.loginContainer {
    &Wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        h2 {
            font-weight: 700;
            font-size: 48px;
        }

        &Title {
            font-weight: 400;
            font-size: 20px;
            padding: 15px 0;
        }

        &Socials {
            display: flex;
            flex-direction: column;
        }

        &Title {
            font-weight: 400;
            font-size: 16px;
            padding-top: 15px;
        }

        button {
            padding: 15px;
            background: $navy;
            color: $white;
            border-radius: 20px;
            font-weight: 700;
            font-size: 18px;
            width: 225px;
            margin-top: 30px;
        }
    }
}
