.pageContainer {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px;
}

.sideNav {
    width: 256px;
    background-color: #d2d2d4;
    border-radius: 20px;
}

.routerContainer {
    width: calc(100% - 300px);
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.mt4Desktop {
    margin-top: 1.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.sideNavItem {
    transition: background-color 0.2s ease-in-out;

    > :global(.nav-link) {
        color: #244371;

        &:hover {
            color: #3c6fbb;
            &:global(.active) {
                color: #fff;
            }
        }
    }
}

:global(.nav-link.active) {
    background-color: #6380aa !important;
}

.icon {
    vertical-align: -0.125em;
    pointer-events: none;
    fill: currentColor;
}

:global(.clickable) {
    cursor: pointer;
}

.logoWrapper {
    height: 30px;
    width: 30px;
}

@media screen and (max-width: 576px) {
    :global(.container) {
        width: 100% !important;
        padding: 0 !important;
    }

    .pageContainer {
        flex-direction: column;
    }

    .sideNav {
        position: fixed;
        left: 0;
        top: 0;
        width: 0;
        z-index: 100;
        height: 100vh;
        overflow-x: hidden;
        padding: 0;
        transition: width 0.35s ease-in-out;
        white-space: nowrap;
    }

    .sideNavOpen {
        width: 100%;
        padding: 1rem;
        margin: 0 !important;
    }

    .routerContainer {
        width: calc(100%);
    }

    .mt4Desktop {
        margin-top: unset;
    }

    .closeMenuButton {
        background-color: transparent;
        color: inherit;
        border: none;
        outline: none;
        font-size: 18px;
    }

    .menuButton {
        width: 100%;
        border: solid 1px #cccccc;
        background-color: #f3f3f3;
    }
}
