.upload-button-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table-header th:not(:last-child) {
    border-right: 1px solid black;
    font-weight: bold;
    text-align: left;
}
